<template>
  <div>
    <page-header has-back></page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <div class="condition">
          <el-tabs v-model="searchForm.type" @tab-click="handleTabsClick">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane label="收入" name="1"></el-tab-pane>
            <el-tab-pane label="支出" name="2"></el-tab-pane>
          </el-tabs>
          <div class="condition-wrapper">
            <el-date-picker
                v-model="dateRange"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                class="condition-item"
                @change="handleCurrentChange(1)"
                :picker-options="pickerOptions">
            </el-date-picker>
            <dict-select v-model="searchForm.cardType" dict-name="vipCardLogType" placeholder="年卡类型"
                         class="condition-item" @change="handleCurrentChange(1)"></dict-select>
            <el-tooltip class="item" effect="dark" content="导出Excel" placement="top-start">
              <el-button class="condition-item" type="default" icon="fa-solid fa-file-export" plain
                         @click="handleExportExcel" :loading="loadingExport"></el-button>
            </el-tooltip>
          </div>
        </div>
        <el-row>
          <el-col :span="24">
            <el-table
                :data="rows"
                v-loading="loading"
                style="width: 100%">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column type="index" label="#"></el-table-column>
              <el-table-column prop="type" label="类型">
                <template slot-scope="scope">
                  <dict-text :code="scope.row.type" dict-name="accountLogType"></dict-text>
                </template>
              </el-table-column>
              <el-table-column prop="changeCount" label="VIP年卡数量" width="140"></el-table-column>
              <el-table-column prop="cardType" label="年卡类型">
                <template v-slot="scope">
                  <dict-text :code="scope.row.cardType" dict-name="vipCardLogType"></dict-text>
                </template>
              </el-table-column>
              <el-table-column prop="surplusCardCount" label="当前剩余"></el-table-column>
              <el-table-column prop="freeCardCount" label="当前剩余(系统赠送)" width="140"></el-table-column>
              <el-table-column prop="payCardCount" label="当前剩余(付费购买)" width="140"></el-table-column>
              <el-table-column prop="remark" label="备注" width="500"></el-table-column>
              <el-table-column prop="creatorName" label="操作人"></el-table-column>
              <el-table-column prop="creationTime" label="创建时间" width="140"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10,20,50,100]"
                :page-size="size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { AccountVipCardLogApi } from '@/api'
import { SHORTCUTS } from '@/utils/contants'
import fileDownload from 'js-file-download'

export default {
  name: 'VipCardLog',
  data () {
    return {
      searchForm: {
        type: '0',
        cardType: '',
        startTime: '',
        endTime: ''
      },
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      dateRange: [],
      pickerOptions: {
        shortcuts: SHORTCUTS
      },
      loadingExport: false
    }
  },
  methods: {
    getSearchParams () {
      const params = {
        page: this.page - 1,
        size: this.size
      }
      if (this.searchForm.type !== '0') {
        params.type = this.searchForm.type
      }
      if (this.searchForm.startTime) {
        params.startTime = this.searchForm.startTime
      }
      if (this.searchForm.endTime) {
        params.endTime = this.searchForm.endTime
      }
      if (this.dateRange && this.dateRange.length === 2) {
        params.startTime = this.dateRange[0]
        params.endTime = this.dateRange[1]
      }
      if (this.searchForm.cardType) {
        params.cardType = this.searchForm.cardType
      }
      return params
    },
    handleSearch () {
      this.loading = true
      const params = this.getSearchParams()
      AccountVipCardLogApi.getList(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleTabsClick () {
      this.handleCurrentChange(1)
    },
    handleExportExcel () {
      if (this.dateRange.length !== 2) {
        this.$message.error('请选择日期范围')
        return
      }
      this.loadingExport = true
      const params = {}
      if (this.searchForm.type !== '0') {
        params.type = this.searchForm.type
      }
      if (this.dateRange && this.dateRange.length === 2) {
        params.startTime = this.dateRange[0]
        params.endTime = this.dateRange[1]
      }
      if (this.searchForm.cardType) {
        params.cardType = this.searchForm.cardType
      }
      AccountVipCardLogApi.exportExcel(params).then(res => {
        this.loadingExport = false
        const data = new Blob([res.data])
        const fileName = 'VIP年卡明细.xls'
        fileDownload(data, fileName)
      })
    }
  },
  created: function () {
    this.handleSearch()
  }
}
</script>

<style scoped lang="less">
.condition {
  position: relative;

  .condition-wrapper {
    position: absolute;
    right: 10px;
    top: 0;
    display: flex;
    align-items: center;

    .condition-item {
      margin-left: 10px;
    }
  }
}
</style>
